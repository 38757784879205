import styled from 'utils/emotion';
import { colors, sizes } from 'components/common/Layout/variables';
import HeadlineGold from 'components/common/HeadlineGold';

export const StyledFeedbackBox = styled('div')(
  {
    padding: 10,
    marginBottom: 20,
  },
  ({ type }) => ({
    backgroundColor:
      type === 'error'
        ? colors.error
        : type === 'success'
        ? colors.success
        : 'rgba(255, 255, 255, .2)',
  }),
);

export const HeadlineGoldStyled = styled(HeadlineGold)({
  marginBottom: sizes.spaceDouble,
});
