import React from 'react';
import PropTypes from 'prop-types';

import { parseAsContent } from 'utils/parser';

import VideoTeaser from 'components/common/VideoTeaser';

import { VideoGridStyled, VideoGridItem } from './VideoGridStyled';

export default function VideoGrid({ videos }) {
  return videos.length >= 0 ? (
    <VideoGridStyled>
      {videos.map(video => {
        const parsedEmbed = parseAsContent(video.embed?.content);

        return (
          <VideoGridItem
            key={`video-element-${video.id}`}
            mobile={1}
            tabletFluid={1 / 2}
            laptop={1 / 2}
          >
            <VideoTeaser
              title={video.title}
              description={video.description}
              embed={
                parsedEmbed?.props?.children?.length > 0 ? parsedEmbed : null
              }
            />
          </VideoGridItem>
        );
      })}
    </VideoGridStyled>
  ) : null;
}

VideoGrid.propTypes = {
  videos: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      embed: PropTypes.object,
    }),
  ),
};

VideoGrid.defaultProps = {
  videos: [],
};
