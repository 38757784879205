import styled from 'utils/emotion';
import { toPx } from 'utils/styles';
import media from 'utils/mediaqueries';

export const LinktreeStyled = styled('div')({
    width: '100%',
    marginBottom: 24,
    marginTop: -48,
    
    [media('tablet')]: {
      marginTop: 48,
    },
  });