import media from 'utils/mediaqueries';
import styled from 'utils/emotion';

export const PageBuilderCodeStyled = styled('div')({
    width: '100%',
    maxWidth: '920px',
    padding: '0 24px',
    margin: '0 auto',
    [media('tablet')]: {
        padding: '0 60px',
    },
    [media('tabletFluid')]: {
        padding: '0 80px',
    },
});
export const PageBuilderCodeScriptStyled = styled('script')({});
