import React, { useEffect }  from 'react';
import { graphql } from 'gatsby';

import { 
  PageBuilderCodeStyled,
  PageBuilderCodeScriptStyled
} from './PageBuilderCodeStyled';

export default function PageBuilderCode({ data }) {
  const {
    pageBuilderCode
  } = data;

  useEffect(()=>{

    let script = document.createElement('script');
    script.src = pageBuilderCode;

    script.id = "CookieDeclaration";
    script.type = "text/javascript";
    script.async = true;
    document.getElementById('cookie-content').appendChild(script);

    return () => {
      if (document.getElementById('cookie-content')) {
        document.getElementById('cookie-content').removeChild(script)
      }
    }
   
  }, []);

  return (
    <PageBuilderCodeStyled id="cookie-content"></PageBuilderCodeStyled>
  );
}

export const query = graphql`
  fragment PageBuilderCodeQuery on Craft_PageBuilderNeoCode {
    id
    pageBuilderCode
  }
`;
