import React from 'react';
import { FormField, SubmitButton } from 'components/common/Form';
import { useFormFields } from 'utils/hooks/useFormFields';
import { useCraftFormSubmit } from 'utils/hooks/useCraftFormSubmit';
import { parseAsContent } from 'utils/parser';
import Grid from 'components/common/Grid';
import GridItem from 'components/common/GridItem';
import Image from 'components/common/ImageGatsbyCraft';
import RichText from 'components/common//RichText';

import { StyledFeedbackBox } from './ContactFormStyled';

function ContactForm({
  formHeadline,
  formPrivacyText,
  formReceiversLabel,
  formEmailLabel,
  formEmailPlaceholder,
  formNameLabel,
  formNamePlaceholder,
  formMessageLabel,
  formMessagePlaceholder,
  formSalutationLabel,
  formSalutationValues,
  formSuccessText,
  formErrorText,
  formSubmitButtonText,
  formSubmitButtonTextSending,
  formBackground,
  formEmailReceivers = [],
}) {
  const receiverOptions = formEmailReceivers
    .filter(({ enabled }) => Boolean(enabled))
    .reduce(
      (receiverOptions, { receiverName, receiverEmail }) => ({
        ...receiverOptions,
        [receiverEmail]: receiverName,
      }),
      {},
    );

  const [formRef, getFormProps, getFieldProps] = useFormFields({
    toEmail: Object.keys(receiverOptions)[0],
    subject: 'Nachricht vom Kontaktformular auf Warsteiner.de',
    salutation: formSalutationValues[0].salutationValue,
  });

  const [handleSubmit, { loading, success, errors }] = useCraftFormSubmit();

  return (
    <>
      <Grid>
        <GridItem mobile={0} tabletFluid={1 / 2} laptop={1 / 2}>
          {formBackground?.[0] && (
            <Image
              fluid={formBackground[0]}
              objectFit="contain"
              imgStyle={{ objectFit: 'contain', maxHeight: 600 }}
            />
          )}
        </GridItem>
        <GridItem mobile={1} tabletFluid={1 / 2} laptop={1 / 2}>
          <RichText>{parseAsContent(formHeadline.content)}</RichText>
          <form
            ref={formRef}
            {...getFormProps({
              onSubmit: handleSubmit,
            })}
          >
            <input {...getFieldProps({ name: 'subject', type: 'hidden' })} />
            <FormField
              label={formReceiversLabel}
              message={errors?.['toEmail']}
              {...getFieldProps({ name: 'toEmail', type: 'select' })}
              options={receiverOptions}
              required
            />
            <FormField
              label={formSalutationLabel}
              message={errors?.['salutation']}
              {...getFieldProps({ name: 'salutation', type: 'select' })}
              options={formSalutationValues.reduce(
                (values, { salutationValue }) => ({
                  ...values,
                  [salutationValue]: salutationValue,
                }),
                {},
              )}
              required
            />
            <FormField
              label={formNameLabel}
              placeholder={formNamePlaceholder}
              message={errors?.['fromName']}
              {...getFieldProps({ name: 'fromName', type: 'text' })}
              required
            />
            <FormField
              label={formEmailLabel}
              placeholder={formEmailPlaceholder}
              message={errors?.['fromEmail']}
              required
              {...getFieldProps({ name: 'fromEmail', type: 'email' })}
            />
            <FormField
              label={formMessageLabel}
              placeholder={formMessagePlaceholder}
              message={errors?.['message']}
              required
              {...getFieldProps({ name: 'message', type: 'textarea' })}
            />
            <FormField
              label={formPrivacyText.content}
              message={errors?.['gdpr']}
              required
              {...getFieldProps({ name: 'gdpr', type: 'checkbox' })}
            />

            {success ? (
              <StyledFeedbackBox type="success">
                {formSuccessText}
              </StyledFeedbackBox>
            ) : null}
            {errors ? (
              <StyledFeedbackBox type="error">
                {formErrorText}
              </StyledFeedbackBox>
            ) : null}

            <SubmitButton type="submit">
              {loading ? formSubmitButtonTextSending : formSubmitButtonText}
            </SubmitButton>
          </form>
        </GridItem>
      </Grid>
    </>
  );
}

export default ContactForm;
