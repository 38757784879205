import React from 'react';
import { graphql } from 'gatsby';

/**
 * Page builder component:
 * Anchor
 */
export default function PageBuilderAnchor({ data }) {
  const { anchorSlug } = data;

  return <div id={anchorSlug} />;
}

export const query = graphql`
  fragment PageBuilderAnchorQuery on Craft_PageBuilderNeoAnchor {
    id
    anchorSlug
  }
`;
