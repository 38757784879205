import styled from 'utils/emotion';
import media from 'utils/mediaqueries';

// Flickity v2.1.2
export const SliderFlickityStyled = styled('div')(
  {
    '.flickity-enabled': {
      position: 'relative',
    },

    '.flickity-enabled:focus': {
      outline: 0,
    },

    '.flickity-viewport': {
      overflow: 'hidden',
      position: 'relative',
      height: '100%',
      transition: 'height 0.2s',
    },

    '.flickity-slider': {
      position: 'absolute',
      width: '100%',
      height: '100%',
    },

    // draggable

    '.flickity-enabled.is-draggable': {
      WebkitTapHighlightColor: 'transparent',
      TapHighlightColor: 'transparent',
      WebkitUserSelect: 'none',
      MozUserSelect: 'none',
      msUserSelect: 'none',
      UserSelect: 'none',
    },

    '.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down': {
      cursor: 'grabbing',
    },

    // Flickity button

    '.flickity-button': {
      position: 'absolute',
      // background: 'hsla(0, 0%, 100%, 0.75)',
      border: 'none',
      color: '#333',
      top: '75%',
      background: 'none',

      [media('tabletFluid')]: {
        top: 'calc(50% - 40px)',
      },
    },

    '.flickity-button:hover': {
      // background: 'white',
      cursor: 'pointer',
    },

    '.flickity-button:focus': {
      outline: 0,
      boxShadow: '0 0 0 5px #19F',
    },

    '.flickity-button:active': {
      opacity: 0.6,
    },

    '.flickity-button:disabled': {
      opacity: 0,
      cursor: 'auto',
      pointerEvents: 'none', // prevent disabled button from capturing pointer up event. #716
    },

    '.flickity-button-icon': {
      fill: '#333',
    },

    // Previous/next buttons

    '.flickity-prev-next-button': {
      top: '50%',
      width: '32px',
      height: '32px',
      borderRadius: '50%',
      transform: 'translateY(-50%)', // vertically center
      display: 'none',

      [media('tabletFluid')]: {
        display: 'block',
      },
    },

    '.flickity-prev-next-button.previous': {
      left: '12px',
    },
    '.flickity-prev-next-button.next': {
      right: '12px',
    },

    // Right to left

    '.flickity-rtl .flickity-prev-next-button.previous': {
      left: 'auto',
      right: '10px',
    },

    '.flickity-rtl .flickity-prev-next-button.next': {
      right: 'auto',
      left: '10px',
    },

    '.flickity-prev-next-button .flickity-button-icon': {
      position: 'absolute',
      left: '20%',
      top: '20%',
      width: '60%',
      height: '60%',
    },

    // Pagination dots

    '.flickity-page-dots': {
      position: 'absolute',
      width: '100%',
      bottom: '-25px',
      padding: 0,
      margin: 0,
      listStyle: 'none',
      textAlign: 'center',
      lineHeight: '1',

      [media('tabletFluid')]: {
        display: 'none',
      },
    },

    '.flickity-rtl .flickity-page-dots': {
      direction: 'rtl',
    },

    '.flickity-page-dots .dot': {
      display: 'inline-block',
      width: 8,
      height: 8,
      margin: '0 5px',
      border: '1px solid currentColor',
      borderRadius: '50%',
      cursor: 'pointer',
      opacity: 1,
    },

    '.flickity-page-dots .dot.is-selected': {
      background: 'currentColor',
    },
  },
  (props) => ({
    '.flickity-page-dots .dot': {
      color: props.theme.colorTertiary,
    },

    '.flickity-page-dots .dot.is-selected': {
      color: props.theme.colorTertiary,
    },

    svg: {
      fill: `${props.theme.colorTertiary} !important`,
    },
  }),
);
