import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { parseAsContent } from 'utils/parser';

import GridItem from 'components/common/GridItem';
import Image from 'components/common/ImageGatsbyCraft';

import { 
  LinktreeItemStyled,
  LinktreeItemTextStyled,
  LinktreeItemImageStyled,
  LinktreeItemContainerStyled,
  LinktreeItemHeadlineStyled,
  LinktreeItemContentContainerStyled,
  LinktreeItemLinkStyled,
  LinktreeItemDarkenStyled
} from './LinktreeItemStyled';


export default function LinktreeItem({
  linktreeItemHeadline,
  linktreeItemText,
  linktreeItemLink,
  linktreeItemLinkText,
  linktreeItemLinkTarget,
  linktreeItemImage,
  linktreeDarkenTile,
  linktreeDarkenTileBackground,
  linktreeDarkenTileOpacity,
  linktreeDarkenText,
  linktreeDarkenTextBackground,
  linktreeDarkenTextOpacity,
  linktreeColumnsMobile,
  linktreeColumnsTablet,
  linktreeColumnsDesktop
}) {
 return (
    <GridItem mobile={1 / linktreeColumnsMobile} tabletFluid={1 / linktreeColumnsTablet} laptop={1 / linktreeColumnsDesktop}>
      <LinktreeItemStyled>
        <LinktreeItemContainerStyled>
          <LinktreeItemLinkStyled to={linktreeItemLink} target={linktreeItemLinkTarget}>
            <LinktreeItemImageStyled>
              <Image
                fadeIn={false}
                fluid={linktreeItemImage}
                style={{ position: 'relative' }}
                args={{}}
                altText={linktreeItemImage?.altText}
              />
            </LinktreeItemImageStyled>
            <LinktreeItemContentContainerStyled
              darkenText={linktreeDarkenText}
              darkenTextBackground={linktreeDarkenTextBackground}
              darkenTextOpacity={linktreeDarkenTextOpacity}
            >
              <LinktreeItemHeadlineStyled>
                {linktreeItemLinkText}
              </LinktreeItemHeadlineStyled>
              {linktreeItemText?.content && (
                <LinktreeItemTextStyled element='div'>
                  {parseAsContent(linktreeItemText?.content)}
                </LinktreeItemTextStyled>
              )}
            </LinktreeItemContentContainerStyled>
            {linktreeDarkenTile && (
              <LinktreeItemDarkenStyled
                element='div'
                darkenTileBackground={linktreeDarkenTileBackground}
                darkenTileOpacity={linktreeDarkenTileOpacity}
              ></LinktreeItemDarkenStyled>
            )}
          </LinktreeItemLinkStyled>
        </LinktreeItemContainerStyled>
      </LinktreeItemStyled>
    </GridItem>
  );
}

LinktreeItem.propTypes = {};

LinktreeItem.defaultProps = {};