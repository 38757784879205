import styled from 'utils/emotion';

import { sizes } from 'components/common/Layout/variables';

export const VideoTeaserStyled = styled('div')({});

export const VideoWrapper = styled('div')({
  position: 'relative',
  marginBottom: sizes.space,
});
