import React from 'react';
import { graphql } from 'gatsby';
import { parseAsContent } from 'utils/parser';

import WrapPbSection from 'components/common/WrapPbSection';
import WrapSection from 'components/common/WrapSection';

import RichText from 'components/common/RichText';

import { RichTextInnerStyled } from './PageBuilderRichTextStyled';

import {
  TextTeaserButtonStyled,
  TextTeaserButtonWrapStyled,
} from 'components/common/TextTeaser/TextTeaserStyled';

export default function PageBuilderRichText({ data }) {
  const {
    richtextContent,
    richtextContentMore,
    richtextContentMoreLabel,
    richtextGoldenBackground,
    teaserLinkTitle,
    teaserLinkField,
    spaceAbove,
    spaceBelow
  } = data;

  const buttonPrimaryTheme = "default"

  return (
    <WrapPbSection goldenBackground={richtextGoldenBackground}>
      <WrapSection
        spaceAbove={spaceAbove}
        spaceBelow={spaceBelow}
      >
        <RichTextInnerStyled>
          <RichText
            moreText={parseAsContent(richtextContentMore?.content)}
            moreTextLabel={richtextContentMoreLabel}
            goldenBackground={richtextGoldenBackground}
          >
            {parseAsContent(richtextContent?.content)}
          </RichText>
          {(teaserLinkTitle) && (
            <TextTeaserButtonWrapStyled>
              <TextTeaserButtonStyled
                to={teaserLinkField?.entry
                  ? teaserLinkField?.entry?.fullUri
                  : teaserLinkField?.url}
                target={teaserLinkField?.target}
                themeName={buttonPrimaryTheme}
                goldenBackground={richtextGoldenBackground}
              >
                {teaserLinkTitle}
              </TextTeaserButtonStyled>
            </TextTeaserButtonWrapStyled>
          )}
        </RichTextInnerStyled>
      </WrapSection>
    </WrapPbSection>
  );
}

export const query = graphql`
  fragment PageBuilderRichTextQuery on Craft_PageBuilderNeoRichText {
    id
    richtextContent {
      content
    }
    richtextContentMoreLabel
    richtextContentMore {
      content
    }
    richtextGoldenBackground,
    teaserLinkTitle
    teaserLinkField {
      entry {
        id
        fullUri
      }
      url
      target
    },
    spaceAbove,
    spaceBelow
  }
`;
