import styled from 'utils/emotion';
import { toPx } from 'utils/styles';
import media from 'utils/mediaqueries';

import GridItem from 'components/common/GridItem';
import Embed from 'components/common/Embed';
import Icon from 'components/common/Icon';
import Text from 'components/common/Text';
import Link from 'components/common/Link';
import HeadlineGold from 'components/common/HeadlineGold';
import { findLastIndex } from 'lodash';

export const LinktreeItemStyled = styled('div')({
  width: '100%',
  marginBottom: 24,

  [media('tablet')]: {
    marginBottom: 48
  },
});

export const LinktreeItemContainerStyled = styled('div')({
  display: 'flex'
});

export const LinktreeItemContentContainerStyled = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate( -50%, -50%)',
  textAlign: 'center',
  zIndex: 1
},
(props) =>
  props.darkenText != false && {
    background: props.darkenTextBackground+props.darkenTextOpacity,
    padding: 12
});

export const LinktreeItemTextStyled = styled(Text)({
});

export const LinktreeItemImageStyled = styled('div')({
  width: '100%',
  marginBottom: 0
});

export const LinktreeItemHeadlineStyled = styled(HeadlineGold)(
  {
    fontSize: toPx(44),
    lineHeight: toPx(40),
    hyphens: 'auto',
    marginBottom: 0,

    [media('tablet')]: {
      fontSize: toPx(64),
      lineHeight: toPx(64),
      letterSpacing: toPx(-1),
    },

    [media('tabletFluid')]: {
      hyphens: 'manual',
    },

    'strong, b': {
      letterSpacing: toPx(-1),
    },
  },
);

export const LinktreeItemLinkStyled = styled(Link)({
  display: 'block',
  width: '100%',
  position: 'relative'
});

export const LinktreeItemDarkenStyled = styled('div')({
  display: 'block',
  position: 'absolute',
  zIndex: 0,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0
},
(props) => ({
  background: props.darkenTileBackground,
  opacity: props.darkenTileOpacity
})
);
