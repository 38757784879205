import styled from 'utils/emotion';
import media from 'utils/mediaqueries';

import FlickityComponent from 'components/common/FlickityComponent';
import WrapSection from 'components/common/WrapSection';
import GridItem from 'components/common/GridItem';
import TextTeaser from 'components/common/TextTeaser';

export const SectionProductsFlickityStyled = styled(FlickityComponent)(
  {
    position: 'relative',
    outline: 0,

    '.flickity-viewport': {
      overflow: 'hidden',
    },

    // fade
    '.flickity-slider > *': {
      pointerEvents: 'none',
      zIndex: 0,
    },

    '.flickity-slider > .is-selected': {
      pointerEvents: 'auto',
      zIndex: 1,
    },

    // prev next
    '.flickity-button': {
      background: 'transparent',
    },
    '.flickity-prev-next-button': {
      position: 'absolute',
      top: '50%',
      width: '100px',
      height: '100px',
      border: 0,
      cursor: 'pointer',
      transform: 'translate(0, -50%)',
      transition: 'transform 0.2s ease-in-out',

      '&.previous:hover': {
        transform: 'translate(-20%, -50%)',
      },

      '&.next:hover': {
        transform: 'translate(20%, -50%)',
      },
    },
    '.flickity-button:disabled': {
      display: 'none',
    },
  },
  (props) => ({
    '.flickity-prev-next-button': {
      width: props.theme.spaceDouble,
      height: props.theme.spaceDouble,
      padding: props.theme.spaceHalf,

      '&:hover .flickity-button-icon': {
        fill: props.theme.colorPrimary,
      },
    },
    '.previous': {
      left: props.theme.spaceHalf,
    },
    '.next': {
      right: props.theme.spaceHalf,
    },
    '.flickity-button-icon': {
      fill: props.theme.colorTertiary,
      transition: 'fill 0.2s ease-in-out',
    },
  }),
);

export const SectionProductsStyled = styled(WrapSection)({
  width: '100vw',
  minHeight: '60vh',
  [media('tabletFluid')]: {
    minHeight: '100vh',
  },
});

export const SectionProductsGridItemStyled = styled(GridItem)({
  [media('tabletFluid')]: {
    marginLeft: '50%',
  },
});

export const SectionProductsTextTeaserStyled = styled(TextTeaser)(
  {
    minHeight: 600,
  },
  (props) => ({
    [media('mobile', 'tabletFluid')]: {
      paddingRight: props.theme.space,
    },
    [media('tabletFluid')]: {
      paddingLeft: props.theme.spaceDouble,
    },
  }),
);
