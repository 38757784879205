import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import WrapPbSection from 'components/common/WrapPbSection';
import WrapSection from 'components/common/WrapSection';
import Linktree from 'components/common/Linktree';

/**
 * Page builder component:
 * Linktree
 */
export default function PageBuilderLinktree(
  { 
    data
  }
) {
  const { children } = data;

  return (
    <WrapPbSection>
      <WrapSection>
        <Linktree
          entries={children}
          linktreeColumnsMobile={data.linktreeColumnsMobile}
          linktreeColumnsTablet={data.linktreeColumnsTablet}
          linktreeColumnsDesktop={data.linktreeColumnsDesktop}
        />
      </WrapSection>
    </WrapPbSection>
  );
}

export const query = graphql`
fragment PageBuilderLinktreeQuery on Craft_PageBuilderNeoLinktree {
  linktreeColumnsMobile
  linktreeColumnsTablet
  linktreeColumnsDesktop
  children {
    __typename
    ... on Craft_PageBuilderNeoLinktreeItem {
      id
      linktreeHeadline {
        content
      }
      linktreeText {
        content
      }
      linktreeLink {
        entry {
          id
          fullUri,
          title
        }
        url
        target,
        customText
      }
      linktreeImage {
        ...CraftImageFluid
      }
      linktreeDarkenTile
      linktreeDarkenTileBackground {
        hex
      }
      linktreeDarkenTileOpacity
      linktreeDarkenText
      linktreeDarkenTextBackground {
        hex
      }
      linktreeDarkenTextOpacity
    }
  }
}
`;
