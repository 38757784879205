import React from 'react';
import { FormField, SubmitButton } from 'components/common/Form';
import { useFormFields } from 'utils/hooks/useFormFields';
import { useCraftFormSubmit } from 'utils/hooks/useCraftFormSubmit';
import { parseAsContent } from 'utils/parser';
import Grid from 'components/common/Grid';
import GridItem from 'components/common/GridItem';

import { StyledFeedbackBox, HeadlineGoldStyled } from './VisitFormStyled';

function VisitForm({
  formHeadline,
  formPrivacyText,
  formEmailReceiver,
  formSalutationLabel,
  formSalutationValues,
  formNameLabel,
  formNamePlaceholder,
  formZipLabel,
  formZipPlaceholder,
  formStreetLabel,
  formStreetPlaceholder,
  formCountryLabel,
  formCountryValues,
  formPhoneLabel,
  formPhonePlaceholder,
  formEmailLabel,
  formEmailPlaceholder,
  formFaxLabel,
  formFaxPlaceholder,
  formDateLabel,
  formDatePlaceholder,
  formTimeLabel,
  formTimeValues,
  formAttendanceLabel,
  formAttendancePlaceholder,
  formMessageLabel,
  formMessagePlaceholder,
  formSubmitButtonText,
  formSubmitButtonTextSending,
  formSuccessText,
  formErrorText,
}) {
  const [formRef, getFormProps, getFieldProps] = useFormFields({
    toEmail: formEmailReceiver,
    subject: 'Anfrage für Brauereibesichtigung auf Warsteiner.de',
    salutation: formSalutationValues[0].salutationValue,
    country: formCountryValues[0].countryValue,
    time: formTimeValues[0].timeValue,
  });

  const [handleSubmit, { loading, success, errors }] = useCraftFormSubmit();

  return (
    <form
      ref={formRef}
      {...getFormProps({
        onSubmit: handleSubmit,
      })}
    >
      <Grid>
        <GridItem mobile={1} tabletFluid={1} laptop={1}>
          <HeadlineGoldStyled>
            {parseAsContent(formHeadline.content, { onlyBasicTags: true })}
          </HeadlineGoldStyled>
        </GridItem>
        <GridItem mobile={1} tabletFluid={1 / 2} laptop={1 / 2}>
          <input {...getFieldProps({ name: 'toEmail', type: 'hidden' })} />
          <input {...getFieldProps({ name: 'subject', type: 'hidden' })} />
          <FormField
            label={formSalutationLabel}
            message={errors?.['salutation']}
            {...getFieldProps({ name: 'salutation', type: 'select' })}
            options={formSalutationValues.reduce(
              (values, { salutationValue }) => ({
                ...values,
                [salutationValue]: salutationValue,
              }),
              {},
            )}
            required
          />
          <FormField
            label={formNameLabel}
            placeholder={formNamePlaceholder}
            message={errors?.['name']}
            required
            {...getFieldProps({ name: 'name', type: 'text' })}
          />
          <FormField
            label={formEmailLabel}
            placeholder={formEmailPlaceholder}
            message={errors?.['fromEmail']}
            required
            {...getFieldProps({ name: 'fromEmail', type: 'email' })}
          />
          <FormField
            label={formPhoneLabel}
            placeholder={formPhonePlaceholder}
            message={errors?.['phone']}
            {...getFieldProps({ name: 'phone', type: 'text' })}
          />
          <FormField
            label={formFaxLabel}
            placeholder={formFaxPlaceholder}
            message={errors?.['fax']}
            {...getFieldProps({ name: 'fax', type: 'text' })}
          />
          <FormField
            label={formStreetLabel}
            placeholder={formStreetPlaceholder}
            message={errors?.['street']}
            {...getFieldProps({ name: 'street', type: 'text' })}
          />
          <FormField
            label={formZipLabel}
            placeholder={formZipPlaceholder}
            message={errors?.['zip']}
            {...getFieldProps({ name: 'zip', type: 'text' })}
          />
          <FormField
            label={formCountryLabel}
            message={errors?.['country']}
            {...getFieldProps({ name: 'country', type: 'select' })}
            options={formCountryValues.reduce(
              (values, { countryValue }) => ({
                ...values,
                [countryValue]: countryValue,
              }),
              {},
            )}
          />
        </GridItem>
        <GridItem mobile={1} tabletFluid={1 / 2} laptop={1 / 2}>
          <FormField
            label={formAttendanceLabel}
            placeholder={formAttendancePlaceholder}
            message={errors?.['attendance']}
            required
            {...getFieldProps({ name: 'attendance', type: 'number' })}
          />
          <FormField
            label={formDateLabel}
            placeholder={formDatePlaceholder}
            message={errors?.['date']}
            required
            {...getFieldProps({ name: 'date', type: 'text' })}
          />
          <FormField
            label={formTimeLabel}
            message={errors?.['time']}
            {...getFieldProps({ name: 'time', type: 'select' })}
            options={formTimeValues.reduce(
              (values, { timeValue }) => ({
                ...values,
                [timeValue]: timeValue,
              }),
              {},
            )}
          />
        </GridItem>
        <GridItem mobile={1} tabletFluid={1} laptop={1}>
          <FormField
            label={formMessageLabel}
            placeholder={formMessagePlaceholder}
            message={errors?.['messageOptional']}
            {...getFieldProps({ name: 'messageOptional', type: 'textarea' })}
          />
          <FormField
            label={formPrivacyText.content}
            message={errors?.['gdpr']}
            required
            {...getFieldProps({ name: 'gdpr', type: 'checkbox' })}
          />

          {success ? (
            <StyledFeedbackBox type="success">
              {formSuccessText}
            </StyledFeedbackBox>
          ) : null}
          {errors ? (
            <StyledFeedbackBox type="error">{formErrorText}</StyledFeedbackBox>
          ) : null}

          <SubmitButton type="submit">
            {loading ? formSubmitButtonTextSending : formSubmitButtonText}
          </SubmitButton>
        </GridItem>
      </Grid>
    </form>
  );
}

export default VisitForm;
