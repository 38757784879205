import React from 'react';
import PropTypes from 'prop-types';

import styled from 'utils/emotion';
import { toPx } from 'utils/styles';

const StyledOverlay = styled('div')(
  {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
  },
  (props) => ({
    zIndex: props.zIndex || '0',
  }),
);

const StyledGradientTop = styled('div')(
  {
    position: 'absolute',
    left: 0,
    top: toPx(-1), // avoid retina gaps ~DH
    width: '100%',
  },
  (props) => ({
    height: props.verticalGradientHeight || '20%',
    zIndex: props.zIndex || '0',
    background: props.theme[props.background] || props.background,
  }),
);

const StyledGradientBottom = styled('div')(
  {
    position: 'absolute',
    left: 0,
    bottom: toPx(-1), // avoid retina gaps ~DH
    width: '100%',
  },
  (props) => ({
    height: props.verticalGradientHeight || '20%',
    zIndex: props.zIndex || '0',
    background: props.theme[props.background] || props.background,
  }),
);

const StyledGradientLeft = styled('div')(
  {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    height: '100%',
  },
  (props) => ({
    width: props.horizontalGradientWidth || '20%',
    zIndex: props.zIndex || '0',
    background: props.theme[props.background] || props.background,
  }),
);

const StyledGradientRight = styled('div')(
  {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    height: '100%',
  },
  (props) => ({
    width: props.horizontalGradientWidth || '20%',
    zIndex: props.zIndex || '0',
    background: props.theme[props.background] || props.background,
  }),
);

export default function OverlayGradients({
  backgroundTop,
  backgroundBottom,
  backgroundLeft,
  backgroundRight,
  zIndex,
  horizontal,
  verticalGradientHeight,
  horizontalGradientWidth,
  className,
}) {
  const validThemeKeys = {
    overlayGradientBackgroundTop: 'overlayGradientBackgroundTop',
    overlayGradientBackgroundBottom: 'overlayGradientBackgroundBottom',
    overlayGradientBackgroundLeft: 'overlayGradientBackgroundLeft',
    overlayGradientBackgroundRight: 'overlayGradientBackgroundRight',
  };

  return (
    <StyledOverlay className={className}>
      <StyledGradientTop
        background={validThemeKeys[backgroundTop]}
        verticalGradientHeight={verticalGradientHeight}
        zIndex={zIndex}
      />
      {horizontal && (
        <>
          <StyledGradientLeft
            background={validThemeKeys[backgroundLeft]}
            horizontalGradientWidth={horizontalGradientWidth}
            zIndex={zIndex}
          />
          <StyledGradientRight
            background={validThemeKeys[backgroundRight]}
            horizontalGradientWidth={horizontalGradientWidth}
            zIndex={zIndex}
          />
        </>
      )}
    </StyledOverlay>
  );
}

OverlayGradients.propTypes = {
  backgroundTop: PropTypes.string,
  backgroundBottom: PropTypes.string,
  backgroundLeft: PropTypes.string,
  backgroundRight: PropTypes.string,
};

OverlayGradients.defaultProps = {
  backgroundTop: 'overlayGradientBackgroundTop',
  backgroundBottom: 'overlayGradientBackgroundBottom',
  backgroundLeft: 'overlayGradientBackgroundLeft',
  backgroundRight: 'overlayGradientBackgroundRight',
};
