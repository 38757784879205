import React from 'react';
import { graphql } from 'gatsby';
import VisitForm from 'components/common/VisitForm';
import WrapPbSection from 'components/common/WrapPbSection';
import WrapSection from 'components/common/WrapSection';

export default function PageBuilderVisitForm({ data }) {
  const {
    formHeadline,
    formPrivacyText,
    formEmailReceiver,
    formSalutationLabel,
    formSalutationValues,
    formNameLabel,
    formNamePlaceholder,
    formZipLabel,
    formZipPlaceholder,
    formStreetLabel,
    formStreetPlaceholder,
    formCountryLabel,
    formCountryValues,
    formPhoneLabel,
    formPhonePlaceholder,
    formEmailLabel,
    formEmailPlaceholder,
    formFaxLabel,
    formFaxPlaceholder,
    formDateLabel,
    formDatePlaceholder,
    formTimeLabel,
    formTimeValues,
    formAttendanceLabel,
    formAttendancePlaceholder,
    formMessageLabel,
    formMessagePlaceholder,
    formSubmitButtonText,
    formSubmitButtonTextSending,
    formSuccessText,
    formErrorText,
  } = data;

  return (
    <WrapSection>
      <VisitForm
        formHeadline={formHeadline}
        formPrivacyText={formPrivacyText}
        formEmailReceiver={formEmailReceiver}
        formSalutationLabel={formSalutationLabel}
        formSalutationValues={formSalutationValues}
        formNameLabel={formNameLabel}
        formNamePlaceholder={formNamePlaceholder}
        formZipLabel={formZipLabel}
        formZipPlaceholder={formZipPlaceholder}
        formStreetLabel={formStreetLabel}
        formStreetPlaceholder={formStreetPlaceholder}
        formCountryLabel={formCountryLabel}
        formCountryValues={formCountryValues}
        formPhoneLabel={formPhoneLabel}
        formPhonePlaceholder={formPhonePlaceholder}
        formEmailLabel={formEmailLabel}
        formEmailPlaceholder={formEmailPlaceholder}
        formFaxLabel={formFaxLabel}
        formFaxPlaceholder={formFaxPlaceholder}
        formDateLabel={formDateLabel}
        formDatePlaceholder={formDatePlaceholder}
        formTimeLabel={formTimeLabel}
        formTimeValues={formTimeValues}
        formAttendanceLabel={formAttendanceLabel}
        formAttendancePlaceholder={formAttendancePlaceholder}
        formMessageLabel={formMessageLabel}
        formMessagePlaceholder={formMessagePlaceholder}
        formSubmitButtonText={formSubmitButtonText}
        formSubmitButtonTextSending={formSubmitButtonTextSending}
        formSuccessText={formSuccessText}
        formErrorText={formErrorText}
      />
    </WrapSection>
  );
}

export const query = graphql`
  fragment PageBuilderVisitFormQuery on Craft_PageBuilderNeoVisitForm {
    id
    formHeadline {
      content
    }
    formPrivacyText {
      content
    }
    formEmailReceiver
    formSalutationLabel
    formSalutationValues {
      salutationValue
    }
    formNameLabel
    formNamePlaceholder
    formZipLabel
    formZipPlaceholder
    formStreetLabel
    formStreetPlaceholder
    formCountryLabel
    formCountryValues {
      countryValue
    }
    formPhoneLabel
    formPhonePlaceholder
    formEmailLabel
    formEmailPlaceholder
    formFaxLabel
    formFaxPlaceholder
    formDateLabel
    formDatePlaceholder
    formTimeLabel
    formTimeValues {
      timeValue
    }
    formAttendanceLabel
    formAttendancePlaceholder
    formMessageLabel
    formMessagePlaceholder
    formSubmitButtonText
    formSubmitButtonTextSending
    formSuccessText
    formErrorText
  }
`;
