import styled from 'utils/emotion';
import { toPx } from 'utils/styles';
import media from 'utils/mediaqueries';
import { sizes } from 'components/common/Layout/variables';

import Grid from 'components/common/Grid';
import GridItem from 'components/common/GridItem';

export const VideoGridStyled = styled(Grid)({
  [media('desktop')]: {
    margin: `0 ${toPx(sizes.spaceFive)}`,
  },
});

export const VideoGridItem = styled(GridItem)({
  marginBottom: sizes.spaceDouble,

  [media('tablet')]: {
    marginBottom: sizes.spaceTriple,
  },
});
