import React from 'react';
import PropTypes from 'prop-types';

import styled from 'utils/emotion';
import { toPercent } from 'utils/math';

const EmbedStyled = styled('div')(
  {},
  (props) => ({
    position: 'relative',
    paddingTop: toPercent(props.aspectRatio),
  }),
  (props) =>
    !props.multiple && {
      '> div:first-of-type, > img:first-of-type, > a:first-of-type, > span:first-of-type': {
        display: 'block',
        position: 'absolute !important',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
      },
    },
);

export default function Embed({ children, className, aspectRatio, multiple }) {
  return (
    <EmbedStyled
      className={className}
      aspectRatio={aspectRatio}
      multiple={multiple}
    >
      {children}
    </EmbedStyled>
  );
}

Embed.propTypes = {
  aspectRatio: PropTypes.number,
  multiple: PropTypes.bool,
};

Embed.defaultProps = {
  aspectRatio: 16 / 9,
  multiple: false,
};
