import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import Grid from 'components/common/Grid';
import LinktreeItem from 'components/common/LinktreeItem';

import { LinktreeStyled } from './LinktreeStyled';

export default function Linktree(
  { 
    entries,
    linktreeColumnsMobile,
    linktreeColumnsTablet,
    linktreeColumnsDesktop
  }
) {
  return entries?.length >= 0 ? (
    <LinktreeStyled>
      <Grid>
        {entries.map((entry, index) => (
          <LinktreeItem
              linktreeItemHeadline={entry?.linktreeHeadline}
              linktreeItemText={entry?.linktreeText}
              linktreeItemLink={
                entry?.linktreeLink
                  ? entry?.linktreeLink?.entry
                    ? entry?.linktreeLink?.entry?.fullUri
                    : entry?.linktreeLink?.url
                  : entry?.linktreeLink?.[0]?.fullUri
              }
              linktreeItemLinkText={
                entry?.linktreeLink
                  ? entry?.linktreeLink?.customText
                  ? entry?.linktreeLink?.customText
                    : entry?.linktreeLink?.entry
                      ? entry?.linktreeLink?.entry?.title
                      : entry?.linktreeLink?.title
                  : entry?.linktreeLink?.[0]?.title
              }
              linktreeItemLinkTarget={
                entry?.linktreeLink?.target
                  ? entry?.linktreeLink?.target
                  : '_self'
              }
              linktreeItemImage={entry?.linktreeImage?.[0]}
              linktreeColumnsMobile={
                linktreeColumnsMobile
                  ? linktreeColumnsMobile
                  : 1
              }
              linktreeColumnsTablet={
                linktreeColumnsTablet
                  ? linktreeColumnsTablet
                  : 2
              }
              linktreeColumnsDesktop={
                linktreeColumnsDesktop
                  ? linktreeColumnsDesktop
                  : 2
              }
              linktreeDarkenTile = {entry?.linktreeDarkenTile}
              linktreeDarkenTileBackground={
                entry?.linktreeDarkenTileBackground?.hex
                  ? entry.linktreeDarkenTileBackground?.hex
                  : '#000000'
              }
              linktreeDarkenTileOpacity={
                entry?.linktreeDarkenTileOpacity
                  ? entry.linktreeDarkenTileOpacity/100
                  : 0.5
              }
              linktreeDarkenText = {entry?.linktreeDarkenText}
              linktreeDarkenTextBackground={
                entry?.linktreeDarkenTextBackground?.hex
                  ? entry.linktreeDarkenTextBackground?.hex
                  : '#000000'
              }
              linktreeDarkenTextOpacity={
                entry?.linktreeDarkenTextOpacity
                  ? entry.linktreeDarkenTextOpacity
                  : 50
              }
            />
        ))}
      </Grid>
    </LinktreeStyled>
  ) : null;
}

Linktree.propTypes = {
  entries: PropTypes.array.isRequired,
};

Linktree.defaultProps = {};
