import styled from 'utils/emotion';

import SliderFlickity from 'components/common/SliderFlickity';

export const SliderFlickityStyled = styled(SliderFlickity)({});

export const SliderItemStyled = styled('div')({
  width: '100%',
  marginBottom: 24,
});
