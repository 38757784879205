import React from 'react';
import PropTypes from 'prop-types';

import Headline from 'components/common/Headline';
import Stripe from 'components/common/Stripe';
import Text from 'components/common/Text';

import { VideoTeaserStyled, VideoWrapper } from './VideoTeaserStyled';

export default function VideoTeaser({ embed, title, description }) {
  return (
    <VideoTeaserStyled>
      {embed && <VideoWrapper>{embed}</VideoWrapper>}
      {title && (
        <>
          <Headline element="h5">{title}</Headline>
          <Stripe />
        </>
      )}
      {description && <Text>{description}</Text>}
    </VideoTeaserStyled>
  );
}

VideoTeaser.propTypes = {
  embed: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
};

VideoTeaser.defaultProps = {
  embed: null,
  title: '',
  description: '',
};
