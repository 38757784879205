import React from 'react';

function Select({
  name,
  value,
  type,
  label,
  placeholder,
  onChange,
  options,
  ...rest
}) {
  return (
    <select id={name} name={name} value={value} onChange={onChange} {...rest}>
      {placeholder && <option value="">{placeholder}</option>}
      {Object.keys(options).map(optionName => (
        <option key={`${name}-${optionName}`} value={optionName}>
          {options[optionName]}
        </option>
      ))}
    </select>
  );
}
export default Select;
