import React from 'react';
import { graphql } from 'gatsby';
import ContactForm from 'components/common/ContactForm';
import WrapSection from 'components/common/WrapSection';

export default function PageBuilderContactForm({ data, backgroundImage }) {
  const {
    formHeadline,
    formPrivacyText,
    formBackground,
    formEmailReceivers,
    formReceiversLabel,
    formEmailLabel,
    formEmailPlaceholder,
    formNameLabel,
    formNamePlaceholder,
    formMessageLabel,
    formMessagePlaceholder,
    formSalutationLabel,
    formSalutationValues,
    formSuccessText,
    formErrorText,
    formSubmitButtonText,
    formSubmitButtonTextSending,
  } = data;

  return (
    <WrapSection backgroundImage={backgroundImage}>
      <ContactForm
        formHeadline={formHeadline}
        formPrivacyText={formPrivacyText}
        formBackground={formBackground}
        formEmailReceivers={formEmailReceivers}
        formReceiversLabel={formReceiversLabel}
        formEmailLabel={formEmailLabel}
        formEmailPlaceholder={formEmailPlaceholder}
        formNameLabel={formNameLabel}
        formNamePlaceholder={formNamePlaceholder}
        formMessageLabel={formMessageLabel}
        formMessagePlaceholder={formMessagePlaceholder}
        formSalutationLabel={formSalutationLabel}
        formSalutationValues={formSalutationValues}
        formSuccessText={formSuccessText}
        formErrorText={formErrorText}
        formSubmitButtonText={formSubmitButtonText}
        formSubmitButtonTextSending={formSubmitButtonTextSending}
      />
    </WrapSection>
  );
}

export const query = graphql`
  fragment PageBuilderContactFormQuery on Craft_PageBuilderNeoContactForm {
    id
    formHeadline {
      content
    }
    formPrivacyText {
      content
    }
    formBackground {
      url
      ...CraftImageFluid
      focalPoint {
        x
        y
      }
    }
    formEmailReceivers {
      ... on Craft_FormEmailReceiversReceiverType {
        enabled
        receiverName
        receiverEmail
      }
    }
    formReceiversLabel
    formEmailLabel
    formEmailPlaceholder
    formNameLabel
    formNamePlaceholder
    formMessageLabel
    formMessagePlaceholder
    formSuccessText
    formErrorText
    formSubmitButtonText
    formSubmitButtonTextSending
    formSalutationLabel
    formSalutationValues {
      salutationValue
    }
  }
`;
