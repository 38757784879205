import { useState, useCallback, useEffect, useRef } from 'react';

function initialValueForType(type) {
  return type === 'checkbox' ? false : '';
}

export function useFormFields(initialFormData = {}) {
  const formRef = useRef(null);
  const [formData, setFormData] = useState(initialFormData);

  const onChange = useCallback(
    e => {
      const { name, type, value, checked } = e.target;
      setFormData(formData => ({
        ...formData,
        [name]: type === 'checkbox' ? checked : value,
      }));
    },
    [setFormData],
  );

  useEffect(() => {
    if (formRef.current) {
      Array.from(formRef.current.elements).forEach(({ name, type }) => {
        if (name) {
          setFormData(formData => ({
            [name]: initialValueForType(type),
            ...formData,
          }));
        }
      });
    }
  }, [formRef]);

  function getFieldProps({ name, type, ...rest }) {
    return {
      ...rest,
      type,
      name,
      onChange,
      id: `form_field_${name}`,
      [type === 'checkbox' ? 'checked' : 'value']:
        formData?.[name] || initialValueForType(type),
    };
  }

  function getFormProps({ onSubmit }) {
    return {
      onSubmit(e) {
        e.preventDefault();
        onSubmit(formData);
      },
    };
  }

  return [formRef, getFormProps, getFieldProps];
}
