import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import WrapPbSection from 'components/common/WrapPbSection';
import WrapSection from 'components/common/WrapSection';
import SectionNews from 'components/common/SectionNews';

export default function PageBuilderSlider({ data, backgroundImage }) {
  const { children } = data;
  return (
    <WrapPbSection>
      <WrapSection backgroundImage={backgroundImage} posed>
        <SectionNews entries={children} />
      </WrapSection>
    </WrapPbSection>
  );
}

PageBuilderSlider.propTypes = {
  backgroundImage: PropTypes.object.isRequired,
};

export const query = graphql`
  fragment PageBuilderSliderQuery on Craft_PageBuilderNeoSlider {
    children {
      __typename
      ... on Craft_PageBuilderNeoSlide {
        id
        teaserText
        teaserTextMore {
          content
        }
        teaserTextMoreLabel
        teaserTagline
        teaserTitle {
          content
        }
        teaserLinkTitle
        teaserLink {
          fullUri
        }
        teaserLinkField {
          entry {
            id
            fullUri
          }
          url
          target
        }
        teaserImagePrimary {
          ...CraftImageFluid
        }
        teaserImageSecondary {
          ...CraftImageFluid
        }
        teaserAlignment
      }
    }
  }
`;
