import React from 'react';
import { TextareaStyled } from './TextareaStyled';

function Textarea({
  name,
  value,
  type,
  label,
  placeholder,
  onChange,
  ...rest
}) {
  return (
    <TextareaStyled
      id={name}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      {...rest}
    >
      {value}
    </TextareaStyled>
  );
}
export default Textarea;
