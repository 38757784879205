import React from 'react';
import { graphql } from 'gatsby';
import Stage from 'components/common/Stage';

export default function PageBuilderStage({ data }) {
  const {
    stageImage,
    stageVideo,
    stageTitle,
    stageTagline,
    stageText,
    teaserLinkTitle,
    teaserLinkField
  } = data;

  return (
    <Stage
      image={stageImage}
      headline={stageTitle?.content}
      tagline={stageTagline}
      text={stageText?.content}
      video={stageVideo}
      buttonLink={
        teaserLinkField?.entry
          ? teaserLinkField?.entry?.fullUri
          : teaserLinkField?.url
      }
      buttonText={teaserLinkTitle}
      buttonTarget={teaserLinkField?.target}
    />
  );
}

export const query = graphql`
  fragment PageBuilderStageQuery on Craft_PageBuilderNeoStage {
    id
    stageTagline
    stageTitle {
      content
    }
    stageText {
      content
    }
    stageImage {
      url
      ...CraftImageFluid_Stage
      focalPoint {
        x
        y
      }
    }
    stageVideo {
      url
    }
    teaserLinkTitle
    teaserLinkField {
      entry {
        id
        fullUri
      }
      url
      target
    }
  }
`;
