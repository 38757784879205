import media from 'utils/mediaqueries';
import styled from 'utils/emotion';

export const RichTextInnerStyled = styled('div')({
  maxWidth: '920px',
  padding: '0 24px',
  margin: '0 auto',
  [media('tablet')]: {
    padding: '0 60px',
  },
  [media('tabletFluid')]: {
    padding: '0 80px',
  },
});
