import styled from 'utils/emotion';

import media from 'utils/mediaqueries';

export const ContainerStyled = styled('div')({
  paddingTop: '140px',

  [media('tabletFluid')]: {
    paddingTop: 0,
  },
});
