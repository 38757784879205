import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { useScrolledIntoView } from 'utils/hooks/useScrolledIntoView';

import Grid from 'components/common/Grid';
import GridItem from 'components/common/GridItem';
import Image from 'components/common/ImageGatsbyCraft';

import {
  PosedTarget,
  SlideInPosed,
  SlideInChildPosed,
} from 'components/common/Posed';

import {
  ImageTextStyled,
  ImageTextGridItemStyled,
  ImageTextEmbedStyled,
  ImageTextEmbedImagePrimaryStyled,
  ImageTextEmbedImageSecondaryStyled,
  ImageTextTextTeaserStyled,
  ImageTextSliderChooserStyled,
  ImageTextSliderChooserIconStyled,
  ImageTextSliderChooserPrevStyled,
  ImageTextSliderChooserNextStyled,
  IndicatorStyled,
} from './ImageTextStyled';

export default function SectionImageText({
  tagline,
  headline,
  text,
  buttonLink,
  buttonText,
  buttonTarget,
  imagePrimary,
  imageSecondary,
  imageAlignment,
  spaceAbove,
  align,
  sliderNext,
  sliderPrev,
  sliderIndicatorCurrent,
  sliderIndicatorAll,
  retriggerScrollIntoView,
  flickityRef,
}) {
  const scrollCheckTarget = useRef(null);
  const [hasScrolledIntoView] = useScrolledIntoView({
    target: scrollCheckTarget,
    threshold: 0,
    rootMargin: '-5px',
    retrigger: retriggerScrollIntoView,
  });

  return (
    <PosedTarget ref={scrollCheckTarget}>
      <ImageTextStyled spaceAbove={spaceAbove}>
        <Grid>
          <GridItem mobile={1} tabletFluid={1 / 2} laptop={1 / 2}>
            <ImageTextTextTeaserStyled
              tagline={tagline}
              headline={headline}
              // eslint-disable-next-line no-control-regex
              text={text && text.replace(new RegExp('\r?\n', 'g'), '<br />')}
              buttonLink={buttonLink}
              buttonPrimaryLink={buttonLink}
              buttonPrimaryText={buttonText}
              buttonPrimaryTarget={buttonTarget}
              // buttonSecondaryLink={}
              // buttonSecondaryText={}
              teaserStyledAlign={align}
              hasScrolledIntoView={hasScrolledIntoView}
              flickityRef={flickityRef}
            />
          </GridItem>
          {(imagePrimary || imageSecondary) && (
            <ImageTextGridItemStyled
              mobile={1}
              tabletFluid={1 / 2}
              laptop={1 / 2}
              order={align === 'left' ? 1 : '-1'}
            >
              <SlideInPosed pose={hasScrolledIntoView ? 'shown' : 'hidden'}>
                <ImageTextEmbedStyled aspectRatio={1} multiple>
                  {imagePrimary && (
                    <ImageTextEmbedImagePrimaryStyled
                      align={align}
                      alignImage={imageAlignment}
                      hideMobile={Boolean(imageSecondary)}
                    >
                      <SlideInChildPosed>
                        <Image
                          critical
                          fadeIn={false}
                          fluid={imagePrimary}
                          args={{ maxWidth: 800 }}
                          altText={imagePrimary?.altText}
                        />
                      </SlideInChildPosed>
                    </ImageTextEmbedImagePrimaryStyled>
                  )}
                  {imageSecondary && (
                    <ImageTextEmbedImageSecondaryStyled
                      align={align}
                      alignImage={imageAlignment}
                    >
                      <SlideInChildPosed>
                        <Image
                          critical
                          fadeIn={false}
                          fluid={imageSecondary}
                          args={{ maxWidth: 800 }}
                          altText={imageSecondary?.altText}
                        />
                      </SlideInChildPosed>
                    </ImageTextEmbedImageSecondaryStyled>
                  )}
                </ImageTextEmbedStyled>
                {(sliderPrev || sliderNext) && (sliderIndicatorAll > 1) && (
                  <ImageTextSliderChooserStyled
                    align={align === 'left' ? 'left' : 'right'}
                  >
                    {sliderPrev && (
                      <SlideInChildPosed>
                        <ImageTextSliderChooserPrevStyled onClick={sliderPrev}>
                          <ImageTextSliderChooserIconStyled type="chevronLeft" />
                        </ImageTextSliderChooserPrevStyled>
                      </SlideInChildPosed>
                    )}
                    {sliderIndicatorCurrent && sliderIndicatorAll && (
                      <SlideInChildPosed>
                        <IndicatorStyled>
                          {sliderIndicatorCurrent}/{sliderIndicatorAll}
                        </IndicatorStyled>
                      </SlideInChildPosed>
                    )}
                    {sliderNext && (
                      <SlideInChildPosed>
                        <ImageTextSliderChooserNextStyled onClick={sliderNext}>
                          <ImageTextSliderChooserIconStyled type="chevronRight" />
                        </ImageTextSliderChooserNextStyled>
                      </SlideInChildPosed>
                    )}
                  </ImageTextSliderChooserStyled>
                )}
              </SlideInPosed>
            </ImageTextGridItemStyled>
          )}
        </Grid>
      </ImageTextStyled>
    </PosedTarget>
  );
}

SectionImageText.propTypes = {
  align: PropTypes.string,
  /** Main is secondary and secondary is primary when it comes to imageAlignment! 🤯 */
  imageAlignment: PropTypes.string,
  retriggerScrollIntoView: PropTypes.bool,
};

SectionImageText.defaultProps = {
  align: 'right',
  imageAlignment: 'default',
  retriggerScrollIntoView: false,
};
