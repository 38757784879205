import styled from 'utils/emotion';
import { toPx } from 'utils/styles';
import { sizes, colors, fonts } from 'components/common/Layout/variables';
import Select from '../Select';

const FormFieldSharedStyles = {
  width: '100%',
  textAlign: 'left',
  padding: `${toPx(sizes.spaceThird)} 0`,
};

const FormFieldStyles = {
  ...FormFieldSharedStyles,
  color: colors.brownLight,
  fontFamily: fonts.primary,
  fontSize: '1em',
  display: 'block',
  background: 'transparent',
  border: 0,
  borderBottom: '1px solid rgba(255,255,255,.4)',
  borderRadius: 0,

  '&:focus': {
    outline: 0,
    borderColor: colors.gold,
  },

  // Placeholder
  '::-webkit-input-placeholder': {
    color: 'rgba(255,255,255,.55)',
  },
  '::-moz-placeholder': {
    color: 'rgba(255,255,255,.55)',
  },
  ':-ms-input-placeholder': {
    color: 'rgba(255,255,255,.55)',
  },
  ':-moz-placeholder': {
    color: 'rgba(255,255,255,.55)',
  },

  // Autofill
  '&:-webkit-autofill, &:-webkit-autofill:focus, &:-webkit-autofill:hover, &:-webkit-autofill:active': {
    WebkitTransitionDelay: '99999s',
  },
};

export const FormFieldTextStyled = styled('input')(FormFieldStyles);

export const FormFieldCheckboxStyled = styled('input')({
  position: 'absolute',
  top: 6,
  left: 3,
  opacity: 0,
});

export const FormFieldSelectStyled = styled(Select)(FormFieldStyles, {
  WebkitAppearance: 'none',
  width: 'auto',
  borderBottom: 0,
  paddingRight: '32px',
  backgroundSize: '12px 12px', // IE 11 needs it explicit like that
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center right 10px',
  backgroundImage:
    'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMiA3LjQxIj48dGl0bGU+c2VsZWN0X2Fycm93PC90aXRsZT48cGF0aCBkPSJNMTAuNTksMCw2LDQuNTgsMS40MSwwLDAsMS40MWw2LDYsNi02WiIgc3R5bGU9ImZpbGw6I2RkZDdjYyIvPjwvc3ZnPg==)',
});

export const FormFieldTextareaStyled = styled('textarea')(FormFieldStyles, {
  height: 170,
  resize: 'vertical',
});

export const FormFieldWrapperStyled = styled('div')({
  position: 'relative',
  display: 'block',
  marginBottom: sizes.space,
});

export const FormFieldLabelStyled = styled('label')({
  display: 'block',
  width: '100%',
  textAlign: 'left',
  padding: `${toPx(sizes.spaceThird)} 0`,

  color: colors.gold,
  fontFamily: fonts.primary,
  fontSize: '.8em',
});

export const FormFieldCheckboxLabelStyled = styled('label')({
  textAlign: 'left',
  display: 'flex',
  flexWrap: 'nowrap',
  userSelect: 'none',

  p: {
    margin: 0,
  },

  // Pseudo checkbox
  '&::before': {
    flex: 'none',
    content: '""',
    width: '1em',
    height: '1em',
    boxSizing: 'border-box',
    border: `1px solid ${colors.gold}`,
    boxShadow: 'inset 0px 0px 0px 4px #000',
    display: 'block',
    marginRight: sizes.spaceHalf,
    borderRadius: 6,
    marginTop: 4,
  },

  // Checked pseudo checkbox
  'input[type="checkbox"]:checked + &::before': {
    border: `1px solid ${colors.gold}`,
    backgroundColor: colors.gold,
  },

  // Links in checkbox label
  a: {
    color: colors.gold,
    transition: 'opacity .15s',
  },

  'a:hover': {
    opacity: 0.8,
  },
});

export const MessageStyled = styled('p')({
  fontSize: '.8em',
  color: colors.error,
  margin: `${toPx(sizes.spaceThird)} 0`,
});
