import styled from 'utils/emotion';
import media from 'utils/mediaqueries';

export const VideoWrapperStyled = styled('div')({
  width: '100%',
  padding: '0',
  background: '#000000',

  [media('tablet')]: {
    padding: '0 8%',
  },

  [media('tabletFluid')]: {
    width: '100%',
    padding: '0',
  },

  [media('desktop')]: {
    width: '100%',
    padding: '0',
  },
});

export const VideoWrapperInnerStyled = styled('div')({
  padding: '0',
  width: '100%',
  maxWidth: '920px',
  margin: '0 auto',

  [media('tablet')]: {
    padding: '0 60px',
  },

  [media('tabletFluid')]: {
    padding: '0 80px',
  },

},
(props) => ({
  maxWidth: props.maxWidth,
}),
(props) =>
  props.alignment === 'center' && {
    margin: '0 auto'
  },
(props) =>
  props.alignment === 'right' && {
    margin: '0 0 0 auto'
  }
);
