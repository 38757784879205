import React from 'react';
import { graphql } from 'gatsby';

import WrapPbSection from 'components/common/WrapPbSection';
import WrapSection from 'components/common/WrapSection';
import LocalVideo from 'components/common/LocalVideo';

import {
  VideoWrapperStyled,
  VideoWrapperInnerStyled,
} from './PageBuilderLocalVideoStyled';

export default function PageBuilderLocalVideo({ data }) {
  const {
    video,
    spaceAbove,
    spaceBelow
  } = data;

  console.log(data)

  const localVideoMaxWidth =
    data?.localVideoMaxWidth
      ? data?.localVideoMaxWidth
      : '100%';

  return (
    <WrapPbSection>
      <WrapSection
        spaceAbove={spaceAbove}
        spaceBelow={spaceBelow}
      >
        <VideoWrapperStyled>
          <VideoWrapperInnerStyled maxWidth={localVideoMaxWidth}>
            <LocalVideo
              videoSrcUrl={data?.localVideoFile?.[0].url}
              posterSrcUrl={data?.localVideoPosterImage?.[0]?.url}
              videoData={data?.localVideoFile?.[0]}
              args={{ maxWidth: localVideoMaxWidth }}
            ></LocalVideo>
          </VideoWrapperInnerStyled>
        </VideoWrapperStyled>
      </WrapSection>
    </WrapPbSection>
  );
}

export const query = graphql`
  fragment PageBuilderLocalVideoQuery on Craft_PageBuilderNeoLocalVideo {
    id,
    localVideoMaxWidth,
    localVideoPosterImage {
      id
      url
      size
      folder {
        id
        name
        path
      }
      width
      height
      filename
      extension
      kind
      mimeType
    },
    localVideoFile {
      id
      url
      size
      folder {
        id
        name
        path
      }
      width
      height
      filename
      extension
      kind
      mimeType
    },
    spaceAbove,
    spaceBelow
  }
`;
