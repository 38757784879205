import React from 'react';
import { graphql } from 'gatsby';

import WrapPbSection from 'components/common/WrapPbSection';
import Video from 'components/common/Video';

import {
  VideoWrapperStyled,
  VideoWrapperInnerStyled,
} from './PageBuilderVideoStyled';

export default function PageBuilderVideo({ data }) {
  const { video } = data;

  return (
    <WrapPbSection transparentBg>
      <Video
          fadeIn={false}
          children={data?.videoEmbed?.content}
        />
      </WrapPbSection>
  );
}

export const query = graphql`
  fragment PageBuilderVideoQuery on Craft_PageBuilderNeoVideo {
    id
    videoEmbed {
      content
    }
  }
`;
