import React from 'react';
import PropTypes from 'prop-types';

import { withTheme } from 'utils/emotion';

import SwitchTheme from 'components/common/SwitchTheme';

import {
  StyledButton,
  StyledButtonText,
  StyledButtonIconLeft,
  StyledButtonIconRight,
} from './SubmitButtonStyled';

/**
 * @param {*} param0
 */
function SubmitButton({
  type,
  themeName,
  className,
  children,
  icon,
  iconLeft,
  disabled,
  onClick,
}) {
  return (
    <SwitchTheme name={themeName}>
      <StyledButton
        themeName={themeName}
        className={className}
        icon={icon}
        iconLeft={iconLeft}
        disabled={disabled}
        onClick={onClick}
        type={type}
      >
        {icon && iconLeft && <StyledButtonIconLeft type={icon} />}
        <StyledButtonText>{children}</StyledButtonText>
        {icon && !iconLeft && <StyledButtonIconRight type={icon} />}
      </StyledButton>
    </SwitchTheme>
  );
}

SubmitButton.propTypes = {
  icon: PropTypes.string,
  themeName: PropTypes.string,
  disabled: PropTypes.bool,
  iconLeft: PropTypes.bool,
};

SubmitButton.defaultProps = {
  icon: null,
  themeName: null, // Do not change this ~DH
  disabled: false,
  iconLeft: false,
};

export default withTheme(SubmitButton);
