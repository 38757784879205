import React from 'react';
import { graphql } from 'gatsby';

import WrapPbSection from 'components/common/WrapPbSection';
import WrapSection from 'components/common/WrapSection';
import VideoGrid from 'components/common/VideoGrid';

export default function PageBuilderVideoGrid({ data, backgroundImage }) {
  const { videos } = data;

  return (
    <WrapPbSection>
      <WrapSection backgroundImage={backgroundImage} posed>
        <VideoGrid videos={videos} />
      </WrapSection>
    </WrapPbSection>
  );
}

export const query = graphql`
  fragment PageBuilderVideoGridQuery on Craft_PageBuilderNeoVideoGrid {
    id
    videos: videoGridVideos {
      ... on Craft_VideoGridVideosVgVideo {
        id
        title: vgVideoTitle
        description: vgVideoDesc
        embed: vgVideoEmbed {
          content
        }
      }
    }
  }
`;
