import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import ImageText from 'components/common/ImageText';

import { SliderFlickityStyled, SliderItemStyled } from './SectionNewsStyled';

export default function SectionNews({ entries }) {
  const flickityRef = useRef(null);

  const sliderNext = useCallback(() => {
    if (flickityRef.current) {
      flickityRef.current.next();
    }
  }, [flickityRef]);

  const sliderPrev = useCallback(() => {
    if (flickityRef.current) {
      flickityRef.current.previous();
    }
  }, [flickityRef]);

  return entries?.length >= 0 ? (
    <SliderFlickityStyled
      flickityRef={f => {
        flickityRef.current = f;
      }}
      options={{
        pageDots: true,
        adaptiveHeight: true,
        prevNextButtons: false,
        wrapAround: true,
        dragThreshold: 10,
      }}
    >
      {entries.map((entry, index) => (
        <SliderItemStyled key={entry.id}>
          <ImageText
            sliderNext={sliderNext}
            sliderPrev={sliderPrev}
            sliderIndicatorCurrent={index + 1}
            sliderIndicatorAll={entries?.length}
            teaserStyledAlign={entry?.teaserAlignment}
            tagline={entry?.teaserTagline}
            headline={entry?.teaserTitle}
            isHeadlineLarge={entry?.teaserTitleSize}
            text={entry?.teaserText}
            textMore={entry?.teaserTextMore}
            textMoreLabel={entry?.teaserTextMoreLabel}
            hasStripe={entry?.teaserStripe}
            align={entry?.teaserAlignment}
            buttonLink={
              entry?.teaserLinkField
                ? entry?.teaserLinkField?.entry
                  ? entry?.teaserLinkField?.entry?.fullUri
                  : entry?.teaserLinkField?.url
                : entry?.teaserLink?.[0]?.fullUri
            }
            buttonText={entry?.teaserLinkTitle}
            buttonTarget={
              entry?.teaserLinkField?.target
                ? entry?.teaserLinkField?.target
                : '_self'
            }
            imagePrimary={entry?.teaserImagePrimary?.[0]}
            imageSecondary={entry?.teaserImageSecondary?.[0]}
            flickityRef={flickityRef}
            retriggerScrollIntoView
          />
        </SliderItemStyled>
      ))}
    </SliderFlickityStyled>
  ) : null;
}

SectionNews.propTypes = {
  entries: PropTypes.array.isRequired,
};

SectionNews.defaultProps = {};
