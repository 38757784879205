import React from 'react';
import PropTypes from 'prop-types';

import FlickityComponent from 'components/common/FlickityComponent';

import { SliderFlickityStyled } from './SliderFlickityStyled';

export default function SliderFlickity({
  children,
  className,
  elementType,
  options,
  disableImagesLoaded,
  reloadOnUpdate,
  flickityRef,
}) {
  if (typeof window === 'undefined') {
    return null;
  }

  return (
    <SliderFlickityStyled>
      <FlickityComponent
        className={className}
        elementType={elementType}
        options={options}
        disableImagesLoaded={disableImagesLoaded}
        reloadOnUpdate={reloadOnUpdate}
        flickityRef={flickityRef}
      >
        {children}
      </FlickityComponent>
    </SliderFlickityStyled>
  );
}

SliderFlickity.propTypes = {
  className: PropTypes.string,
  elementType: PropTypes.string,
  options: PropTypes.object,
  disableImagesLoaded: PropTypes.bool,
  reloadOnUpdate: PropTypes.bool,
  flickityRef: PropTypes.func,
};

SliderFlickity.defaultProps = {
  className: 'slider-flickity',
  elementType: 'div',
  disableImagesLoaded: false, // Disable call reloadCells images are loaded
  reloadOnUpdate: false, //	Run reloadCells and resize on componentDidUpdate
  flickityRef: null, // like ref function, get Flickity instance in parent component
  options: {
    autoPlay: false,
    draggable: true,
    contain: true,
    wrapAround: true,
    pageDots: false,
    prevNextButtons: true,
    initialIndex: 0,
    adaptiveHeight: true,
    cellAlign: 'left',
  },
};
