import React from 'react';
import PropTypes from 'prop-types';

import { VideoStyled } from './LocalVideoStyled';

export default function LocalVideo({ videoSrcUrl, videoData, posterSrcUrl }) {
  return (
    <VideoStyled
      controls
      muted
      playsinline
      preload="auto"
      poster={posterSrcUrl}>
      <source src={videoSrcUrl}></source>
    </VideoStyled>
  );
}
