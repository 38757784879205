import React from 'react';
import { graphql } from 'gatsby';

import WrapPbSection from 'components/common/WrapPbSection';
import Image from 'components/common/ImageGatsbyCraft';

import {
  ImageWrapperStyled,
  ImageWrapperInnerStyled,
} from './PageBuilderImageStyled';

export default function PageBuilderImage({ data }) {
  const { image, imageNeoAlignment, imageNeoAltText, imageNeoMaxWidth } = data;

  const maxWidth = 
    imageNeoMaxWidth
      ? imageNeoMaxWidth
      : 800;

    const alignment = 
      imageNeoAlignment
        ? imageNeoAlignment
        : 'center';

  return (
    <WrapPbSection transparentBg>
      <ImageWrapperStyled>
        <ImageWrapperInnerStyled
          alignment={alignment}
          maxWidth={maxWidth}
        >
          <Image
            critical
            fadeIn={false}
            fluid={image?.[0]}
            args={{ maxWidth: maxWidth }}
          />
        </ImageWrapperInnerStyled>
      </ImageWrapperStyled>
    </WrapPbSection>
  );
}

export const query = graphql`
  fragment PageBuilderImageQuery on Craft_PageBuilderNeoImage {
    id
    image {
      ...CraftImageFluid
    }
    imageNeoAlignment
    imageNeoAltText
    imageNeoMaxWidth
  }
`;
