import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { useScrolledIntoView } from 'utils/hooks/useScrolledIntoView';

import ImageGatsbyCraft from 'components/common/ImageGatsbyCraft';
import ImageGatsby from 'components/common/Image';
import OverlayGradients from 'components/common/OverlayGradients';
import { PosedTarget, FadeInEaseOutQuad } from 'components/common/Posed';
import NullWrap from 'components/common/NullWrap';

import {
  WrapSectionStyled,
  BackgroundStyled,
  ContentStyled,
} from './WrapSectionStyled';

export default function WrapSection({
  flex,
  className,
  backgroundImage,
  backgroundImageProducts,
  backgroundFlip,
  children,
  posed,
  spaceAbove,
  spaceBelow
}) {
  const FadeInComponent = posed ? FadeInEaseOutQuad : NullWrap;

  const scrollCheckTarget = useRef(null);
  const [hasScrolledIntoView] = useScrolledIntoView({
    target: scrollCheckTarget,
  });

  return (
    <PosedTarget ref={scrollCheckTarget}>
      <WrapSectionStyled flex={flex} className={className} spaceAbove={spaceAbove} spaceBelow={spaceBelow}>
        {backgroundImage && (
          <FadeInComponent pose={hasScrolledIntoView ? 'shown' : 'hidden'}>
            <BackgroundStyled
              flip={backgroundFlip}
              backgroundImageProducts={backgroundImageProducts}
            >
              {
                // Pick the right Image component.
                // This should be handled different for future projects.
                // I can only do it here since the props are slightly differnt ~DH
                backgroundImage?.__typename === 'Craft_ImagesVolume' ? (
                  <ImageGatsbyCraft
                    critical
                    fadeIn={false}
                    objectFit="cover"
                    objectPosition="center center"
                    aspectRatio={16 / 9}
                    fluid={backgroundImage}
                    args={{ maxWidth: 1920 }}
                  />
                ) : (
                  <ImageGatsby
                    critical
                    fadeIn={false}
                    objectFit="contain"
                    objectPosition="100% 0%"
                    src={backgroundImage}
                  />
                )
              }
              <OverlayGradients />
            </BackgroundStyled>
          </FadeInComponent>
        )}
        <ContentStyled flex={flex}>{children}</ContentStyled>
      </WrapSectionStyled>
    </PosedTarget>
  );
}

WrapSection.propTypes = {
  flex: PropTypes.bool,
  posed: PropTypes.bool,
  backgroundFlip: PropTypes.bool,
};

WrapSection.defaultProps = {
  flex: false,
  posed: false,
  backgroundFlip: false,
};
