import styled from 'utils/emotion';
import media from 'utils/mediaqueries';

export const VideoWrapperStyled = styled('div')({
  width: '100%',
  padding: '25px 0 25px 0',

  [media('tablet')]: {
    padding: '25px 8% 25px 8%',
  },

  [media('tabletFluid')]: {
    width: '100%',
    padding: '0',
  },
  
  [media('desktop')]: {
    width: '100%',
    padding: '50px 0',
  },
});

export const VideoWrapperInnerStyled = styled('div')({
  padding: '0 24px',
  width: '100%',
  
  [media('tabletFluid')]: {
    padding: '0',
  }
},
(props) => ({
  maxWidth: props.maxWidth,
}),
(props) =>
  props.alignment === 'center' && {
    margin: '0 auto'
  },
(props) =>
  props.alignment === 'right' && {
    margin: '0 0 0 auto'
  }
);
